import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Box from 'gatsby-theme-signalwerk/src/components/Box';
export const _frontmatter = {
  "title": "Research",
  "author": "Stefan Huber",
  "date": "2019-10-23",
  "layout": "post",
  "draft": false,
  "hideInMenu": false,
  "path": "/research/",
  "tags": ["TagOne", "TagTwo"],
  "description": ""
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <div {...{
      "className": "toc"
    }}></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#intro"
        }}>{`Intro`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#metafont"
        }}>{`Metafont`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#illustrator"
        }}>{`Illustrator`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#kalliculator"
        }}>{`Kalliculator`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#apparatus"
        }}>{`Apparatus`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#prototype"
        }}>{`Prototype`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#metapolator"
        }}>{`Metapolator`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#programming-libs"
        }}>{`Programming Libs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#type-generator"
        }}>{`Type Generator`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#other-projects"
        }}>{`Other projects`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#algorithms"
        }}>{`Algorithms`}</a></li>
    </ul>
    <undefined></undefined>
    <h2 {...{
      "id": "intro"
    }}>{`Intro`}</h2>
    <p>{`A short overview on parametric vector design.`}</p>
    <h2 {...{
      "id": "metafont"
    }}>{`Metafont`}</h2>
    <p>{`Metafont is a Touring complete programming language with focus on typedesign. The concept is based on strokes and they can be adjusted to not only have letters looking like written with broad edge pen but with a typset character.`}</p>
    <h4 {...{
      "id": "pro"
    }}>{`Pro`}</h4>
    <ul>
      <li parentName="ul">{`Long running Project`}</li>
      <li parentName="ul">{`Finished (by definition)`}</li>
      <li parentName="ul">{`Highly customizable → Turing complete`}</li>
      <li parentName="ul">{`Especially made for Fontdesign`}</li>
    </ul>
    <h4 {...{
      "id": "con"
    }}>{`Con`}</h4>
    <ul>
      <li parentName="ul">{`Output as Pixels`}</li>
      <li parentName="ul">{`Hard to handle and controll (even vor Programmers)`}</li>
      <li parentName="ul">{`No visual editing`}</li>
    </ul>
    <h2 {...{
      "id": "illustrator"
    }}>{`Illustrator`}</h2>
    <p>{`A outline based drawing program based on Cubic Splines`}</p>
    <h4 {...{
      "id": "pro-1"
    }}>{`Pro`}</h4>
    <ul>
      <li parentName="ul">{`Easy to understand`}</li>
    </ul>
    <h4 {...{
      "id": "con-1"
    }}>{`Con`}</h4>
    <ul>
      <li parentName="ul">{`Not parametric`}</li>
      <li parentName="ul">{`Not suitable for fontdesign`}</li>
    </ul>
    <h2 {...{
      "id": "kalliculator"
    }}>{`Kalliculator`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.kalliculator.com/"
      }}>{`kalliculator.com`}</a><br parentName="p"></br>{`
`}{`Application to generate digital representations of a variable and flexible broad edge pen. The with and the angel of each stroke can be modified.`}</p>
    <h4 {...{
      "id": "pro-2"
    }}>{`Pro`}</h4>
    <ul>
      <li parentName="ul">{`flexible control of the skeleton-line`}</li>
      <li parentName="ul">{`parametric pen-parameters`}</li>
    </ul>
    <h4 {...{
      "id": "con-2"
    }}>{`Con`}</h4>
    <ul>
      <li parentName="ul">{`not available to the public`}</li>
      <li parentName="ul">{`not able to generate good contoures`}</li>
    </ul>
    <h2 {...{
      "id": "apparatus"
    }}>{`Apparatus`}</h2>
    <p><a parentName="p" {...{
        "href": "http://aprt.us/"
      }}>{`aprt.us`}</a><br parentName="p"></br>{`
`}{`A full parametric graphic design «constructor». The included constraint solver helps to define relations between visual representation and data. Highly inspired by Bret Victor.`}</p>
    <h4 {...{
      "id": "pro-3"
    }}>{`Pro`}</h4>
    <ul>
      <li parentName="ul">{`fresh and easy to understand (compared to the complexity of the tool)`}</li>
      <li parentName="ul">{`constraint solver feels naturally`}</li>
    </ul>
    <h4 {...{
      "id": "con-3"
    }}>{`Con`}</h4>
    <ul>
      <li parentName="ul">{`No splines, only Polygons`}</li>
    </ul>
    <h2 {...{
      "id": "prototype"
    }}>{`Prototype`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.prototypo.io/"
      }}>{`prototypo.io`}</a></p>
    <Box maxWidth="60" mdxType="Box">
      <p><img parentName="p" {...{
          "src": "https://web.archive.org/web/20170804055955if_/https://www.prototypo.io/content/1-blog/18-versatile-type-design-for-the-web/parametric-font.gif",
          "alt": "Prototypo"
        }}></img>{`
`}<em parentName="p">{`A parametric glyph in motion by Prototypo`}</em></p>
    </Box>
    <p>{`A web-based editor for parametric typefaces. The editor allows to modify but not to create three different parametric typefaces. The .ptf-format (parametric typeface) is is very advanced and allows for complex but free font models.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/byte-foundry/prototypo-builder"
      }}>{`prototypo-builder`}</a>{` is a new Project to generate the .ptf-Files in a Browser.`}</p>
    <h4 {...{
      "id": "pro-4"
    }}>{`Pro`}</h4>
    <ul>
      <li parentName="ul">{`hight quality font models`}</li>
      <li parentName="ul">{`well designed GUI for parameters`}</li>
    </ul>
    <h4 {...{
      "id": "con-4"
    }}>{`Con`}</h4>
    <ul>
      <li parentName="ul">{`Without a deep understanding of .ptf it's not possible to generate new fonts but just to modify existing masters.`}</li>
    </ul>
    <h2 {...{
      "id": "metapolator"
    }}>{`Metapolator`}</h2>
    <p>{`Metapolator is a young project and has a radical domain specific selector/programming language to transform and generate shapes and points. There are some GUI-Elements but that's more to controll a final typedesign and not to support a typedesigner`}</p>
    <h4 {...{
      "id": "pro-5"
    }}>{`Pro`}</h4>
    <ul>
      <li parentName="ul">{`Hobby Splines`}</li>
      <li parentName="ul">{`Point transformation with custom Selector-Language`}</li>
      <li parentName="ul">{`Highly customizable`}</li>
      <li parentName="ul">{`Support by google`}</li>
    </ul>
    <h4 {...{
      "id": "con-5"
    }}>{`Con`}</h4>
    <ul>
      <li parentName="ul">{`Hard to handle and controll (even vor Programmers)`}</li>
      <li parentName="ul">{`Small community`}</li>
    </ul>
    <h2 {...{
      "id": "programming-libs"
    }}>{`Programming Libs`}</h2>
    <p>{`Programming Libs are most of the time spline based drawing abstractions in code. Sometimes they have clearly the focus of typedesing, sometimes they have mor a general outline-based approach.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://paperjs.org/"
        }}>{`Paper.js`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://robofab.org/"
        }}>{`Robofab`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://robofab.org/"
        }}>{`ufo.js`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/devongovett/fontkit"
        }}>{`fontkit`}</a></li>
    </ul>
    <h2 {...{
      "id": "type-generator"
    }}>{`Type Generator`}</h2>
    <p>{`In 2006 Remo Caminada and Ludovic Varone developed (in Flash) for their Diploma at the Zurich University of the Arts a type generator based on a math based model. They won the following awards with it:`}</p>
    <ul>
      <li parentName="ul">{`2007 Red Dot Award, junior prize`}</li>
      <li parentName="ul">{`Föderpreis der ZHdK für die beste Diplomarbeit 2006`}</li>
      <li parentName="ul">{`Schweizer Förderpreis für Deisgn 2007`}</li>
      <li parentName="ul">{`Förderpreis Graubünden 2007`}</li>
    </ul>
    <h3 {...{
      "id": "links"
    }}>{`Links`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medienarchiv.zhdk.ch/people/d26868c4-8fbf-4f8d-ae12-4f7b0ed1ffce"
        }}>{`Pictures`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://de.red-dot.org/2484.html"
        }}>{`Interview`}</a></li>
    </ul>
    <h2 {...{
      "id": "other-projects"
    }}>{`Other projects`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://laikafont.ch/"
        }}>{`Laikafont`}</a>{` – dynamic web typeface`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.haagseletters.nl/"
        }}>{`Typeconstructor`}</a>{` – parametric type design application`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.fontconstructor.com/"
        }}>{`Fontconstructor`}</a>{` – fonteditor based on components`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://glyphrstudio.com/"
        }}>{`Glyphr`}</a>{` – online typedesigner`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://generative-typografie.de/"
        }}>{`Generative Typografie`}</a>{` – tests with coded typefaces`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/anthonydugois/polynom"
        }}>{`Polynom SVG-Webeditor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/studioludens/MagicBox"
        }}>{`MagicBox – Parametric Editor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/vega/lyra"
        }}>{`Lyra – Visualization Design Environment `}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.letterinkapp.com"
        }}>{`Letterink – skeleton type design`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://scripts.sil.org/cms/scripts/page.php?site_id=projects&item_id=graphite_devFont#gdl"
        }}>{`Graphite Description Language`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xibyte/jsketcher"
        }}>{`JS.Sketcher`}</a>{` – Parametric 2D and 3D modeler `}</li>
    </ul>
    <h2 {...{
      "id": "algorithms"
    }}>{`Algorithms`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Hausdorff_distance"
        }}>{`Hausdorff Distance`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Shape_context"
        }}>{`Shape context`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Sirvasile/Typefont"
        }}>{`Hausdorff Distance & Shape context in JS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/icons8/svg-path-bounding-box/"
        }}>{`SVG path bounding box calculator`}</a></li>
    </ul>
    <br />
    <br />
    <br />
    <br />
    <br />
    <h4 {...{
      "id": "source-on-github"
    }}>{`Source on GitHub`}</h4>
    <p>{`Fix my mistakes `}<a parentName="p" {...{
        "href": "https://github.com/signalwerk/paramatters/blob/master/sites/example/src/pages/00--research/index.md"
      }}>{`on GitHub`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      